import { List, Toolbar } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReceiptIcon from '@mui/icons-material/Receipt';
import React from "react";
import { useTranslation } from "react-i18next";
import CustomListItem from "./CustomListItem";
import GroupIcon from '@mui/icons-material/Group';
import RuleIcon from '@mui/icons-material/Rule';
import BexioAuthListItem from "./BexioAuthListItem";

const Navigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Toolbar sx={{ height: 85, display: { xs: "none", sm: "flex" }, "& img": { padding: 1 } }}>
        <img src="/public/img/logo.png" style={{width: "100%"}} />
      </Toolbar>
      <Toolbar sx={{ display: { xs: "flex", sm: "none" } }}>
        <img src="/public/img/logo.png" style={{ width: "100%", padding: "10px 10px 10px 0" }} />
      </Toolbar>
      <List>
        <CustomListItem to="/dashboard" altMatchPath="/detail/:id" icon={<GridViewIcon />} listItemText={t("navigation.dashboard")}  />
        <CustomListItem to="/users" icon={<GroupIcon />} listItemText={t("navigation.userManagement")}  />
        <CustomListItem to="/imports" icon={<ImportExportIcon />} listItemText={t("navigation.import")}  />
        <CustomListItem to="/receipts" icon={<ReceiptIcon />} listItemText={t("navigation.receipt")}  />
        <CustomListItem to="/rules" icon={<RuleIcon />} listItemText={t("navigation.rules")}  />
      </List>
      <List sx={{display: "flex", flexGrow: 1, alignItems: "flex-end", flexDirection: "column", marginBottom: "25px"}}>
        <div style={{flexGrow: 1}}></div>
        <BexioAuthListItem />
        <CustomListItem to="/logout" icon={<LogoutIcon />} listItemText={t("navigation.logout")}  />
      </List>
    </>
  );
};

export default Navigation;
